import { PartnerData } from './involve/corporate-and-organization/social-contribution';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import {
  CorporateControllerService,
  CsrVo,
  FoBannerControllerService,
  FoBannerVo,
  FoMainControllerService,
  PeopleVo,
  SubscribeNewsControllerService,
  reliefPlaceControllerService,
  reliefPlaceVo,
} from '@/__generated__/FrontApi';
import ArrowLeft from '@/assets/img/arrow-left.png';
import ArrowRight from '@/assets/img/arrow-right.png';
import InterviewBg from '@/assets/img/bg_interview_section.png';
import MainActivityBg from '@/assets/img/bg_main_activity.png';
import LinkBanner1 from '@/assets/img/img_link_card_01@2x.png';
import LinkBanner2 from '@/assets/img/img_link_card_02@2x.png';
import LinkBanner3 from '@/assets/img/img_link_card_03@2x.png';
import MainEventImage from '@/assets/img/img_main_event.png';
import KidsFriendlCity from '@/assets/img/main/kids_friendly_city.png';
import KidsFriendlyCorp from '@/assets/img/main/kids_friendly_corp.jpg';
import KidsFriendlyHospital from '@/assets/img/main/kids_friendly_hospital.png';
import KidsFriendlySchool from '@/assets/img/main/kids_friendly_school.png';
import Transparency85 from '@/assets/img/transparency_85.png';
import TransparencyAward from '@/assets/img/transparency_award.jpg';
import TransparencyCncLogo from '@/assets/img/transparency_cnclogo.png';
import TransparencyComment from '@/assets/img/transparency_comment.png';
import TransparencyGuideStar from '@/assets/img/transparency_guidestar.png';
import TransparencyIati from '@/assets/img/transparency_iati.png';
import TransparencyIcon from '@/assets/img/transparency_icon.png';
import TransparencyMoney from '@/assets/img/transparency_money.png';
import TransparencyUn from '@/assets/img/transparency_un.png';
import Button from '@/components/Button';
import BoardBannerCard from '@/components/Card/BoardLatestCard';
import CampaignCard, { CampaignData } from '@/components/Card/CampaignCard';
import ImageLinkCard, { ImageLinkData } from '@/components/Card/ImageLinkCard';
import MainEventCard from '@/components/Card/MainEventCard';
import ReliefPlaceCard from '@/components/Card/ReliefPlaceCard';
import RoundThumbnailCard, {
  RoundData,
} from '@/components/Card/RoundThumbnailCard';
import Container from '@/components/Container';
import EmergencyPopup from '@/components/EmergencyPopup';
import ErrorMessage from '@/components/Form/ErrorMessage';
import Image from '@/components/Image';
import InputNumber from '@/components/Input/InputNumber';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import { TabData } from '@/components/MainTab';
import NewsletterSection from '@/components/Newsletter';
import Section from '@/components/Section';
import Select from '@/components/Select';
import SupportPhrase from '@/components/SupportPhrase';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import {
  BOARD_CATEGORIES,
  BOARD_KIND_EVENT,
  BOARD_KIND_NOTICE,
  BOARD_KIND_PRESS,
} from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import {
  SUPPORT_TERM_ONCE,
  SUPPORT_TERM_REGULAR,
  SUPPORT_CATEGORY_ONCE_WORLD,
  SUPPORT_CATEGORY_REGULAR_WORLD,
} from '@/helpers/SupportHelper';
import MainLayout from '@/layouts/MainLayout';
import { SupportStep1Data } from '@/page-blocks/donate/DonateStep1';
import { usePopupStore } from '@/stores/PopupStore';
import { useLocation } from '@reach/router';
import { Link, navigate, PageProps } from 'gatsby';
import _ from 'lodash';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import SwiperCore, { A11y, Navigation, Scrollbar, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import YouTube, { YouTubePlayer } from 'react-youtube';
import { GtmButtonDonate } from '@/components/Button.gtm';
import { SelectItemDefaultData } from '@/helpers/GtmHelper';
SwiperCore.use([Navigation, Scrollbar, A11y, Pagination]);

const tab: TabData[] = [
  {
    id: 0,
    subject: '차별 없는 구호',
    content: `종교·국적·인종·이념·성별 등과 무관하게
    단 한 명의 어린이도 빠짐없이 전 세계 어린이를 돕습니다.`,
    link: '/what-we-do/no-discrimination/saving-lives',
  },
  {
    id: 1,
    subject: '어린이 삶 개선',
    content: `각 나라 정부와 긴밀하게 협력해 보다 효과적이고 폭넓게
    어린이 삶을 근본적으로 개선합니다.`,
    link: '/what-we-do/building-future/climate-change',
  },
  {
    id: 2,
    subject: '긴급구호',
    content: `전 세계 거의 모든 곳에서 재난 전에도, 재난 중에도,
    재난 후에도, 한결같이 어린이 곁을 지킵니다.`,
    link: '/what-we-do/emergency-relief/covid19-vaccine',
  },
  {
    id: 3,
    subject: '한국 아동권리 증진',
    content: `한국의 유엔아동권리협약 이행을 위해 학교, 기업,
    지방자치단체, 정부와 협력합니다.`,
    link: '/what-we-do/advocacy-for-children/crc-implementation',
  },
  {
    id: 4,
    subject: '한국 정부 협력',
    content: `한국 정부가 도움이 필요한 전 세계 어린이를
    지원할 수 있도록 협력합니다.`,
    link: '/what-we-do/korea-government-cooperation',
  },
];

const contributionImages: ImageLinkData[] = [
  {
    id: 1,
    title: `기업·기관 사회공헌`,
    href: `/involve/corporate-and-organization/social-contribution`,
    src: LinkBanner1,
  },
  {
    id: 2,
    title: `고액·아너스클럽`,
    href: `/involve/individual/honors-club`,
    src: LinkBanner2,
  },
  {
    id: 3,
    title: `유산-추모기부`,
    href: `/involve/individual/legacy`,
    src: LinkBanner3,
  },
];

const Header = styled.header`
  padding-bottom: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .more {
    font-weight: 700;

    ${breakpoint('mobile')} {
      padding-right: 24px;
      height: 24px;

      .txt {
        overflow: hidden;
        position: absolute;
        top: -9999px;
        left: -9999px;
        width: 1px;
        height: 1px;
        font-size: 1px;
        line-height: 1;
      }
    }
  }
`;

const H2Description = styled.p``;

const ButtonWrap = styled.div`
  margin-top: 40px;

  ${breakpoint('tablet')} {
    margin-top: 24px;
  }
`;

const SectionDirect = styled(Section)`
  background-color: #2589c9;
  padding: 48px 0;
  overflow: visible;
`;
const SectionEvent = styled(Section)`
  position: relative;
  padding-top: 120px;
  padding-bottom: 80px;

  ${breakpoint('tablet')} {
    padding-bottom: 30px;
  }

  .content-inner--event {
    background: #1cabe2;
    position: relative;
    width: 100%;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: #fff;
      position: absolute;
      right: 50%;
    }
  }
`;
const BnSlide = styled(Section)`
  ${SwiperBasic} {
    padding: 0 20px;
    max-width: 2064px;
    margin: 0 auto;
  }
`;
const SectionActivity = styled(Section)`
  padding-top: 240px;
`;
const SectionInterview = styled(Section)`
  position: relative;
  padding-top: 120px;

  ${Header} {
    padding-bottom: 130px;

    ${breakpoint('tablet')} {
      padding-bottom: 50px;
    }
  }
`;

const SectionRelief = styled(Section)`
  padding-top: 220px;
  padding-bottom: 120px;
  background: #fff;

  ${Container} {
    max-width: 1370px;
    width: 100%;
    padding: 0;
    ${breakpoint(1370)} {
      padding: 0 20px;
    }
  }

  ${Header} {
    display: block;
    max-width: 1120px;
    padding-bottom: 0;
    margin: 0 auto;
  }
  ${SwiperBasic} {
    padding: 0;

    .swiper-container {
      padding-bottom: 0;
      overflow: hidden;
      ${breakpoint(1370)} {
        max-width: 1120px;
        padding-bottom: 86px;
      }
      ${breakpoint('tablet')} {
        padding-bottom: 86px !important;
      }
      .swiper-button-prev {
        top: 50%;
        right: initial;
        left: 12px;
        ${breakpoint(1370)} {
          top: inherit;
          left: inherit;
          right: 100px;
          margin-right: 0;
        }
        ${breakpoint('tablet')} {
          display: block;
        }
      }
      .swiper-button-next {
        top: 50%;
        left: initial;
        right: 12px;
        margin-right: 0;
        ${breakpoint(1370)} {
          top: inherit;
          right: 20px;
        }
        ${breakpoint('tablet')} {
          display: block;
        }
      }

      .swiper-wrapper {
        .swiper-slide {
          padding-left: 130px;
          padding-right: 130px;
          box-sizing: border-box;
          ${breakpoint(1370)} {
            padding: 0;
          }
          &:first-child {
            margin-left: 0;
          }

          & + .swiper-slide {
            margin-left: 0;
          }
          .relief-tit {
            width: 100%;
            margin-bottom: 45px;
            .underline {
              position: relative;
              z-index: 1;
              &::before {
                content: '';
                display: inline-block;
                width: 100%;
                height: 16px;
                background: rgba(28, 171, 226, 0.3);
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
              }
            }
          }
          .relief-con {
            display: flex;
            justify-content: space-between;
            width: 100%;
            ${breakpoint('mobile')} {
              flex-direction: column;
            }
          }
          .relief-video {
            width: calc(50% - 70px);
            height: 272px;
            margin-right: 70px;
            ${breakpoint('tablet')} {
              width: calc(50% - 40px);
              margin-right: 40px;
            }
            ${breakpoint('mobile')} {
              width: 100%;
              margin-right: 0;
            }
            .youtube_wrap,
            iframe {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .relief-img {
            height: 272px;
            picture,
            source,
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .relief-news {
            width: 50%;
            ${breakpoint('mobile')} {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

const SectionStory = styled(Section)`
  padding-top: 106px;
  padding-bottom: 120px;
  background: #e2f0f6;

  ${breakpoint('tablet')} {
    padding-top: 74px;
  }

  ${Header} {
    padding-bottom: 114px;

    ${breakpoint('tablet')} {
      padding-bottom: 30px;
    }
  }

  .content-section--activity {
    position: relative;
    overflow: hidden;

    h2 {
      margin-bottom: 64px;
    }

    &::before {
      content: '';
      display: block;
      width: 1520px;
      height: 492px;
      background: #e2f0f6 url(${MainActivityBg}) no-repeat right top;
      position: absolute;
      left: 0;
      top: 361px;
      z-index: -1;

      ${breakpoint(1280)} {
        width: 100%;
      }

      ${breakpoint(1024)} {
        top: 233px;
      }

      ${breakpoint(640)} {
        height: 536px;
        background-size: auto 189px;
        background-position: left bottom;
        top: 165px;
      }
    }

    .activity-tabpanel {
      width: 100%;

      ${breakpoint(1120)} {
        padding: 0 20px;
      }

      ${breakpoint(640)} {
        position: relative;
      }
    }

    .content-inner--activity {
      margin-top: 300px;

      ${breakpoint(1024)} {
        margin-top: 380px;
      }

      ${breakpoint(640)} {
        padding: 0;
      }
    }
  }
`;

const SectionForm = styled(Section)`
  padding-top: 57px;
  padding-bottom: 84px;
  background: #e5e6e8;

  ${breakpoint('tablet')} {
    padding-top: 37px;
    padding-bottom: 74px;
  }
`;
// 후원하기 radio 간격조정
const SupporContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-beteen;
  width: 100%;
  position: relative;
  // 버튼 튀어나오게
  z-index: 2;

  .col1 {
    padding-top: 14px;
    width: 160px;
  }

  .col2 {
    width: calc(100% - 296px);
    padding: 0 60px 0 40px;
    padding-top: 14px;

    ${Tit} {
      strong {
        color: #ffdc0e;
      }
    }

    ${breakpoint(1160)} {
      padding-right: 5.17vw;
      padding-left: 3.45vw;
    }

    ${breakpoint(1000)} {
      padding-right: 25px;
      padding-left: 25px;

      ${Tit} {
        display: block;
        width: auto;
        margin-left: -185px;
        margin-right: -161px;
      }
    }

    ${breakpoint('tablet')} {
      ${Tit} {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .col3 {
    width: 136px;
  }

  ${breakpoint('tablet')} {
    padding-right: 161px;

    .col1,
    .col2 {
      width: 100%;
      padding: 0;
      margin-bottom: 16px;
    }

    .col3 {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  ${breakpoint('mobile')} {
    padding-right: 0;

    .col3 {
      transform: translate(0, 0);
      top: auto;
      bottom: -115px;
      width: 120px;

      .btn {
        padding: 0;
        width: 100%;
        height: 120px;
        line-height: 118px;
      }
    }
  }
`;
const InputFlex = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  margin-bottom: 24px;

  & > li {
    width: 25%;
    padding: 0 12px;

    & > * {
      max-width: 100% !important;
    }
  }

  ${breakpoint(1160)} {
    & {
      margin-left: -1.03vw;
      margin-right: -1.03vw;
    }

    & > li {
      padding: 0 1.03vw;
    }
  }

  ${breakpoint(1000)} {
    & {
      margin-left: -10px;
      margin-right: -10px;
    }

    & > li {
      width: 33.33%;
      margin-bottom: 10px;
      padding: 0 10px;

      &:last-child {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  ${breakpoint('tablet')} {
    & {
      margin-left: -6px;
      margin-right: -6px;
    }

    & > li {
      padding: 0 6px;
      margin-bottom: 16px;
    }
  }
`;

const MainSection = styled(Section)`
  height: 900px;
  position: relative;

  ${SwiperBasic} {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 128px;
    padding: 0 20px;
  }

  ${breakpoint(1160)} {
    ${SwiperBasic} {
      bottom: 11.03vw;
    }
  }

  ${breakpoint('tablet')} {
    ${SwiperBasic} {
      bottom: 40px;
    }
  }

  ${breakpoint('mobile')} {
    // background 비디오를 너비 기준으로 출력하는데, px로 고정하니 너비가 좁을때 상단 여백이 발생함. 그래서 vw로 보완.
    height: 140vw;
  }
`;

// 메인 배경 Swiper
const MainSwiper = styled(Swiper)`
  position: absolute;
  height: 100%;
  width: 100%;
  /* navigation */
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    -webkit-filter: drop-shadow(2px 2px 5px #222222);
    filter: drop-shadow(2px 2px 5px #222222);
    color: white;
  }

  .swiper-button-prev {
    background-image: url(${ArrowLeft});
    background-size: contain;
    background-position: 50% 60%;
    background-repeat: no-repeat;
    left: 30px;

    ${breakpoint('mobile')} {
      display: none;
    }
  }

  .swiper-button-next {
    background-image: url(${ArrowRight});
    background-size: contain;
    background-position: 50% 60%;
    background-repeat: no-repeat;
    right: 30px;

    ${breakpoint('mobile')} {
      display: none;
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    max-width: 530px;
    margin: 0 auto;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    background: #aaa !important;
    /* position: absolute; */
    width: 40px;
    margin: 0 3px;
    height: 5px;
    z-index: 20;
    border-radius: 3px;
  }

  .swiper-pagination-bullet-active {
    background: #fff !important;
  }

  // 이전, 다음페이지 없을 시 hidden처리
  .swiper-button-disabled {
    visibility: hidden;
  }
`;

const MainSwiperSlide = styled(SwiperSlide)`
  cursor: pointer;
  /* width: 100vw;
  height: 100vh; */
`;
const MainSwiperSlideContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 150px;
  bottom: 30%;
  font-size: 45px;

  ${breakpoint('mobile')} {
    bottom: 100px;
    left: 30px;
    font-size: 20px;
  }

  .title {
    span {
      white-space: pre;
      background-color: white;
      padding: 0px 10px;
      margin: 10px 0px;
    }

    margin-bottom: 10px;
    color: #1cabe2;
    font-weight: bold;
  }

  .subTitle {
    span {
      background-color: white;
      padding: 1px 10px;
      font-size: 24px;

      ${breakpoint('mobile')} {
        font-size: 14px !important;
      }
    }

    margin-bottom: 20px;
    color: #333;
  }

  .button {
    text-decoration: none;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    box-sizing: border-box;
    /* min-width: 168px; */
    text-align: center;
    /* line-height: 56px; */
    height: 56px;
    padding: 0px 50px;
    align-items: center;
    vertical-align: middle;

    /* padding: 0 50px 0 20px; */
    font-weight: bold;
    cursor: pointer;
    border-color: #1cabe2;
    background: #1cabe2;
    color: white;

    ${breakpoint('mobile')} {
      height: 40px;
      padding: 0 30px;
    }

    &:hover {
      background-color: #374ea2;
      border-color: #374ea2;
    }
  }
`;

// 배경 동영상
const MainBackgroundVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

const MainBackgroundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 120%;
  max-width: inherit;
`;

const MainBackgroundVideoPc = styled(MainBackgroundVideo)`
  height: 120%;

  ${breakpoint('mobile')} {
    display: none;
  }
`;

const MainBackgroundOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(45, 41, 38, 0.3); */
`;

const MainBackgroundVideoM = styled(MainBackgroundVideo)`
  display: none;
  width: 100%;

  ${breakpoint('mobile')} {
    display: block;
  }
`;

const SectionPartner = styled(Section)`
  padding-top: 74px;

  ${breakpoint('mobile')} {
    padding-bottom: 35px;
  }

  .SwiperCustom {
    padding: 0;
  }

  .swiper-container {
    padding-bottom: 74px;
  }

  .link-item {
    display: block;
    padding: 8px;
    position: relative;
    width: 80%;
    height: auto;
    border: 0;
    background: none !important;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: 8px solid transparent;
    }
  }

  ${breakpoint('mobile')} {
    .link-item {
      width: 100%;
    }
  }

  .img-box {
    padding-top: 70%;
    width: 100%;
    background-size: 80%;
  }

  ${breakpoint('mobile')} {
    .img-box {
      background-size: 100%;
    }
  }
`;

const SwiperCustom = styled(SwiperBasic)`
  .swiper-slide {
    width: 328px;

    & + .swiper-slide {
      margin-left: 10px;
    }

    :first-child {
      margin-left: calc((100% - 1120px) / 2);
    }

    :last-child {
      margin-right: calc((100% - 1120px) / 2);
    }
  }

  ${breakpoint('mobile')} {
    .swiper-slide {
      width: 280px;

      & + .swiper-slide {
        margin-left: 16px;
      }

      :first-child {
        margin-left: 0;
      }

      :last-child {
        margin-right: 0;
      }
    }
  }
`;

const KidsFriendlyImageWrapper = styled.div`
  display: grid;
  display: -ms-grid;

  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-columns: 1fr 1fr 1fr;
  // grid-column-gap: 20px;
  // grid-row-gap: 40px;

  ${breakpoint('tablet')} {
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 600;

    :nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;

      ${breakpoint('tablet')} {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        margin-bottom: 40px;
      }
    }

    :nth-child(2) {
      -ms-grid-column: 2;
      -ms-grid-row: 1;

      ${breakpoint('tablet')} {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }
    }

    :nth-child(3) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;

      ${breakpoint('tablet')} {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
    }

    :nth-child(4) {
      -ms-grid-column: 4;
      -ms-grid-row: 1;

      ${breakpoint('tablet')} {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
      }
    }
  }

  .image {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${breakpoint('mobile')} {
      width: 150px;
      height: 150px;
    }
  }

  .image-small {
    width: 170px;
    height: 200px;
    margin-bottom: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${breakpoint('mobile')} {
      width: 120px;
      height: 150px;
    }
  }
`;

const FlexResponsiveWrapper = styled.div`
  .wrapper {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 80px;

    ${breakpoint('tablet')} {
      grid-template-columns: 1fr 1fr 1fr;
    }

    ${breakpoint('mobile')} {
      grid-template-columns: 1fr 1fr;
    }
  }

  // 85퍼센트
  .image85 {
    height: 80px;
    margin-left: 15px;
    margin-right: 54px;

    ${breakpoint(`tablet`)} {
      height: 50px;
      margin-right: 55px;
    }
  }

  /// 한국가이드스타 - 가로 밴드가 있어 크기를 키움
  .image0 {
    height: 70px;
    margin-right: 40px;

    ${breakpoint(`tablet`)} {
      height: 50px;
    }
  }

  // 유엔마크
  .image1 {
    height: 62px;
    margin-right: 30px;

    ${breakpoint(`tablet`)} {
      height: 50px;
    }
  }

  // 세계어린이구호 부문수상
  .image2 {
    height: 70px;
    margin-right: 30px;

    ${breakpoint(`tablet`)} {
      height: 55px;
    }
  }

  // ATI
  .image-iati {
    height: 73px;
    margin-right: 30px;
    ${breakpoint(`tablet`)} {
      height: 58px;
    }
  }

  .focus {
    color: #1cabe2;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px;
  flex-direction: column;

  ${breakpoint('tablet')} {
    margin-bottom: 40px;
  }
  ${breakpoint('mobile')} {
    margin: 0 0 40px 0;
  }

  :nth-child(1) {
    margin-left: 0;
    ${breakpoint('tablet')} {
      margin-left: 20px;
    }
    ${breakpoint('mobile')} {
      margin-left: 0px;
    }
  }
  :nth-child(5) {
    margin-right: 0;
    ${breakpoint('tablet')} {
      margin-right: 20px;
    }
    ${breakpoint('mobile')} {
      margin-right: 0px;
    }
  }

  .img-wrapper {
    width: 120px;
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${breakpoint('mobile')} {
      width: 100px;
      height: 100px;
    }
  }

  // 유니세프 아동권리증진기관
  .img-wrapper-cnc {
    width: 170px;
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${breakpoint('mobile')} {
      width: 150px;
      height: 100px;
    }
    ${breakpoint(425)} {
      width: 130px;
    }
    ${breakpoint(320)} {
      width: 110px;
    }
  }

  // 유니세프 전문성
  .img-wrapper-un {
    width: 130px;
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${breakpoint('mobile')} {
      width: 110px;
      height: 100px;
    }
  }

  .text-wrapper {
    text-align: center;
    margin-top: 30px;
    ${breakpoint('mobile')} {
      padding: 0 40px;
    }
    ${breakpoint(425)} {
      padding: 0 10px;
    }
    ${breakpoint(375)} {
      padding: 0px;
    }
  }
`;

const FlexResponsiveBannerWrapper = styled.div`
  display: flex;
  align-items: start;

  ${breakpoint(`mobile`)} {
    flex-direction: column;
  }

  .banner {
    background-color: #e2f0f6;
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    width: calc(100% - 20px);

    ${breakpoint(`mobile`)} {
      padding: 20px 10px;
      margin-bottom: 20px;
    }
  }

  .image2 {
    height: 80px;
    padding-left: 40px;
    object-fit: contain;

    ${breakpoint(`tablet`)} {
      padding-left: 10px;
      //height: 60px;
    }
  }
`;

const CardLayout = styled.div``;
const CardImage = styled.div``;

const ResponsiveImage = styled.div<{
  mobileImg?: string;
  pcImg?: string;
}>`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `url(${props.pcImg})`};

  ${breakpoint(640)} {
    background-image: ${(props) => `url(${props.mobileImg})`};
  }
`;

// 홈화면 후원하기 폼
const HomeSupportForm: FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<SupportStep1Data>({
    defaultValues: {
      supportAmount: '30000',
      supportAmountInput: '',
      supportTerm: SUPPORT_TERM_REGULAR,
    },
  });

  const onSubmit: SubmitHandler<SupportStep1Data> = ({
    supportAmount,
    supportAmountInput,
    supportTerm,
  }) => {
    navigate(
      `/donate/?supportAmount=${
        supportAmount || ''
      }&supportAmountInput=${supportAmountInput}&supportTerm=${supportTerm}&supportCategory=${
        supportTerm === SUPPORT_TERM_REGULAR
          ? SUPPORT_CATEGORY_REGULAR_WORLD
          : SUPPORT_CATEGORY_ONCE_WORLD
      }`,
    );
  };
  const supportTerm = watch('supportTerm');
  const supportAmount = watch('supportAmount');
  const supportAmountInput = watch('supportAmountInput');
  // 총 후원금액
  const finalAmount = useMemo(
    () => Number(supportAmount || supportAmountInput || 0),
    [supportAmount, supportAmountInput],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SupporContainer>
        <InputRadioGroup className="direct-radio-group col1">
          <Select name="supportTerm" ref={register}>
            <option value={SUPPORT_TERM_REGULAR}>정기</option>
            <option value={SUPPORT_TERM_ONCE}>일시</option>
          </Select>
        </InputRadioGroup>
        <InputRadioGroup className="direct-radio-group col2">
          <InputFlex>
            <li>
              <InputRadio
                name="supportAmount"
                ref={register}
                tab
                outline
                value="30000"
                label="30,000원"
                onClick={() => setValue('supportAmountInput', '')}
              />
            </li>
            <li>
              <InputRadio
                name="supportAmount"
                ref={register}
                tab
                outline
                value="50000"
                label="50,000원"
                onClick={() => setValue('supportAmountInput', '')}
              />
            </li>
            <li>
              <InputRadio
                name="supportAmount"
                ref={register}
                tab
                outline
                value="100000"
                label="100,000원"
                onClick={() => setValue('supportAmountInput', '')}
              />
            </li>
            <li>
              <Controller
                control={control}
                name="supportAmountInput"
                rules={{
                  validate: (value) => {
                    if (supportAmount) {
                      return true;
                    }
                    if (supportTerm === SUPPORT_TERM_REGULAR) {
                      const val = Number(value);
                      if (val < 10000) {
                        return '1만 원 이상부터 정기후원이 가능합니다';
                      }
                      if (val >= 10000000) {
                        return '고액 정기후원은 후원본부(02-737-1004)로 연락주시기 바랍니다';
                      }
                    }
                    return true;
                  },
                }}
                as={
                  <InputNumber
                    onFocus={() => setValue('supportAmount', '')}
                    placeholder="금액 직접 입력"
                    allowNegative={false}
                  />
                }
              />
              {errors.supportAmountInput && (
                <ErrorMessage>{errors.supportAmountInput.message}</ErrorMessage>
              )}
            </li>
          </InputFlex>
          {supportTerm === SUPPORT_TERM_REGULAR && (
            <Tit size="s4-1" color="white">
              <SupportPhrase supportAmount={finalAmount} />
            </Tit>
          )}
          {supportTerm === SUPPORT_TERM_ONCE && (
            <Tit size="s4-1" color="white">
              유니세프와 함께하는 <span>나눔의 첫 걸음</span>, 모이면{' '}
              <span>기적</span>이 만들어집니다.
            </Tit>
          )}
        </InputRadioGroup>
        <div className="button--main-direct col3">
          <GtmButtonDonate
            type="submit"
            text="후원하기"
            color="yellow"
            shape="circle"
            className="btn-support-main"
            goto="/donate"
            gtmInfo={{
              ...SelectItemDefaultData,
              itemName: '세계어린이돕기',
              itemListName: '',
              itemBreadscrumb: 'main',
            }}
            onClickPreEvent={() => {
              // TODO onClick before call gtm
              // alert('click');
            }}
          />
        </div>
      </SupporContainer>
    </form>
  );
};

// 홈화면 구독하기 폼
interface HomeSubscribeFormData {
  name: string;
  email: string;
  entryPath: string;
}

const HomeSubscribeForm: React.FC = () => {
  const { register, handleSubmit, reset } = useForm<HomeSubscribeFormData>();
  const popupStore = usePopupStore();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const trackcode = queryParams.get(`trackcode`);

  const onSubmit: SubmitHandler<HomeSubscribeFormData> = async ({
    name,
    email,
  }) => {
    try {
      if (
        !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email.trim())
      ) {
        alert('이메일 주소가 올바르지 않습니다.');
        return;
      }
      const {
        resultCode,
      } = await SubscribeNewsControllerService.postSubscribeNewsUserUsingPost({
        email,
        name,
        entryPath: trackcode ?? undefined,
      });

      if (resultCode === 'success') {
        popupStore.show(
          '유니세프 소식 받아보기 신청이 완료되었습니다.\n유니세프와 함께 전 세계 어린이들에 대한 다양한\n이야기를 들어보세요.',
        );
        // 폼 초기화
        reset();
      }
    } catch (e) {
      if (e.response) {
        popupStore.show(e.response.data.data.returnMessage);
      }
    }
  };

  return (
    <NewsletterSection id="UnicefNewsletter">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className="inner-content">
          <Header className="sec-header">
            <h2>
              <Tit size="s2">
                유니세프와 함께하는 소식을 <br />
                놓치지 마세요
              </Tit>
            </h2>
          </Header>
          <div className="newsletter-input-contents">
            <div className="form-flex">
              <div className="form-flex-cell">
                <div className="form-flex-cell newsletter-cell-name">
                  <InputText
                    name="name"
                    ref={register({ required: true })}
                    placeholder="이름 입력"
                  />
                </div>
                <div className="form-flex-cell newsletter-cell-email">
                  <InputText
                    name="email"
                    ref={register({ required: true })}
                    placeholder="이메일 입력"
                  />
                </div>
                <p className="private-text">
                  소식 전달을 목적으로 이름, 이메일주소를 수집하며, 수신거부 시
                  까지 이용 ∙보유합니다.
                </p>
              </div>
              {/* pc 오른쪽 */}
              <button type="submit" className="button-submit-skyblue">
                소식 받아보기
              </button>
            </div>
          </div>
        </Container>
      </form>
    </NewsletterSection>
  );
};

const checkLink = (category: string, index: string) => {
  if (category === BOARD_KIND_NOTICE) {
    return `/about-us/notice/${index}`;
  }
  if (category === BOARD_KIND_PRESS) {
    return `/about-us/press/${index}`;
  }
  return `/what-we-do/news/${index}`;
};

const clickPartners = () => {
  window.location.href =
    '/involve/corporate-and-organization/social-contribution';
};
const Home: React.FC<PageProps> = () => {
  const [banners, setBanners] = useState<CampaignData[]>([]);
  const [campaigns, setCampaigns] = useState<CampaignData[]>([]);
  const [people, setPeople] = useState<RoundData[]>([]);
  const [partners, setPartners] = useState<PartnerData[]>([]);
  const [news, setNews] = useState<any[]>([]);
  const [reliefPlaces, setReliefPlaces] = useState<any[]>([]);
  const [reliefPlacesYt, setReliefPlacesYt] = useState<any[]>([]);

  // 메인 배너 로드
  const loadBanners = useCallback(async () => {
    try {
      const {
        data: mainBanners,
      } = await FoBannerControllerService.selectBannerListUsingGet({
        kind: '2001',
      });
      setBanners(
        mainBanners.map((banner: FoBannerVo) => ({
          id: banner.indexNumber,
          pcImage: banner.imgAttGrpNoPc,
          mobileImage: banner.imgAttGrpNoMobile,
          title: banner.title,
          subTitle: banner.subTitle,
          link: banner.targetUrl,
          linkTargetBlank: banner.targetBlankYn === 'Y',
          donateUrl: banner.donateUrl,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 메인 캠페인 로드
  const loadCampaigns = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_EVENT],
        page: 1,
        pagePerCount: 12,
        eventStatus: 'B',
        topYn: 'Y',
      });
      const campaignList = data as any;
      setCampaigns(
        campaignList.map((campaign: BoardVo) => ({
          id: campaign.boardIndexNumber,
          pcImage: campaign.imgAttGrpNoTn,
          mobileImage: campaign.imgAttGrpNoTn,
          link: campaign.referUrl,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 메인 사람들 로드
  const loadPeople = useCallback(async () => {
    try {
      const {
        data: mainPeople,
      } = await FoMainControllerService.getUnicefPeopleListUsingGet();
      setPeople(
        mainPeople
          .sort((a, b) => {
            const cateA = a.categoryCode === 'P02' ? 'P01' : a.categoryCode;
            const cateB = b.categoryCode === 'P02' ? 'P01' : b.categoryCode;
            return (
              cateA.localeCompare(cateB) ||
              new Date(b.firstRegisterDate) - new Date(a.firstRegisterDate)
            );
          })
          .map((person: PeopleVo) => ({
            id: person.peopleIndexNumber,
            categoryType: person.categoryName,
            category: person.role,
            message: `“${person.mainText}”`,
            name: person.name,
            src: person.attGrpNoMain,
            relUrl:
              person.detailPageYn === 'Y'
                ? `/about-us/people${person.relUrl}`
                : '/about-us/people/',
          })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 파트너 로고 로드
  const loadPartners = useCallback(async () => {
    try {
      const {
        data: organizationLogo,
      } = await CorporateControllerService.getCsrLogoListUsingGet();
      setPartners(
        organizationLogo.map((logo: CsrVo) => ({
          id: logo.indexNumber,
          indexNumber: logo.indexNumber,
          title: logo.csrName,
          category1: logo.csrCate1,
          category2: logo.csrCate2,
          url: logo.csrSite,
          year: logo.csrYear,
          desc: logo.csrDesc,
          imgPath: logo.logoAttGrpNo,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 구호 현장 로드
  const loadReliefPlaces = useCallback(async () => {
    try {
      const {
        data: reliefPlaceList,
      } = await reliefPlaceControllerService.getReliefPlaceListUsingGet();
      setReliefPlaces(
        reliefPlaceList.map((relief: reliefPlaceVo) => ({
          attGrpNoMobile: relief.attGrpNoMobile,
          attGrpNoPc: relief.attGrpNoPc,
          imgAttGrpNoMobile: relief.imgAttGrpNoMobile,
          imgAttGrpNoPc: relief.imgAttGrpNoPc,
          indexNumber: relief.indexNumber,
          keyword: relief.keyword,
          visualType: relief.visualType,
          visualUri: relief.visualUri,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadNews = useCallback(async () => {
    try {
      const {
        data: optData1,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['100', '110', '280', '290', '285', '295', '300'],
        pagePerCount: 12,
        optData1: 'Y',
      });
      const {
        data: optData2,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['100', '110', '280', '290', '285', '295', '300'],
        pagePerCount: 12,
        optData1: 'Y',
        optData2: 'Y',
      });
      let articles;
      if (optData2?.length > 0) {
        articles = _.uniqBy([...optData1, ...optData2], 'boardIndexNumber');
      } else {
        articles = optData1 as any;
      }
      setNews(
        articles
          ?.map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            boardCategoryCode: article.boardCategoryCode,
            category:
              article.boardCategoryCode &&
              BOARD_CATEGORIES[article.boardCategoryCode],
            subject: article.subject,
            date: article.dispStartDate,
            link: checkLink(
              article.boardCategoryCode,
              article.boardIndexNumber,
            ),
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            optData1: article.optData1,
            optData2: article.optData2,
          }))
          .sort(
            (a, b) =>
              (a.optData2 === 'Y' ? -1 : 0) ||
              new Date(b.date.replaceAll('.', '/')) -
                new Date(a.date.replaceAll('.', '/')),
          )
          .slice(0, 12),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 구호 현장 슬라이드 제어
  const reliefSwiper = useRef<SwiperCore>();
  const reliefInit = (swiper: SwiperCore): void => {
    reliefSwiper.current = swiper;
  };
  const reliefSwiperMouseOn = () => {
    reliefSwiper?.current?.autoplay.stop();
  };
  const reliefSwiperMouseLeave = () => {
    reliefSwiper?.current?.autoplay.start();
  };
  const reliefSwiperChange = (e: any) => {
    let ytId: any = '';
    reliefSwiper?.current?.slides.map((slide, idx) => {
      if (idx === reliefSwiper?.current?.activeIndex) {
        ytId = slide.querySelector('.youtube_wrap iframe')?.id || '';
      }
    });
    for (const ytObjId in reliefPlacesYt) {
      reliefPlacesYt[ytObjId].stopVideo();
    }
    /*
    // 포커스가 있는 슬라이드 유튜브 자동 재생
    if (ytId) {
      reliefPlacesYt[ytId].playVideo();
    }
    */
  };

  useEffect(() => {
    loadBanners();
    loadCampaigns();
    loadPeople();
    loadPartners();
    loadNews();
    loadReliefPlaces();
  }, [
    loadBanners,
    loadCampaigns,
    loadNews,
    loadPartners,
    loadPeople,
    loadReliefPlaces,
  ]);

  // 임시 : 메인 배경 타입 - video 또는 image
  // const mainBackgroundType = `video`;

  const mainSwiper = useRef<SwiperCore>();

  const onInit = (swiper: SwiperCore): void => {
    mainSwiper.current = swiper;
  };

  const handleMouseEnter = () => {
    if (mainSwiper.current) mainSwiper.current.autoplay.stop();
  };
  const handleMouseLeave = () => {
    if (mainSwiper.current) mainSwiper.current.autoplay.start();
  };

  function relief(value: any, index: number, array: any[]): unknown {
    throw new Error('Function not implemented.');
  }

  // 한글 문자열의 마지막 글자가 종성이 있는지 여부를 판단하는 함수
  function hasJongSeong(str) {
    const code = str.charCodeAt(str.length - 1);
    return (code - 0xac00) % 28 !== 0;
  }
  // 종성에 따라 조사를 붙이는 함수
  function attachParticle(str) {
    return hasJongSeong(str) ? '은' : '는';
  }

  return (
    <MainLayout>
      <MainSection>
        {/* <CampaignCard campaigns={banners} /> */}
        {/* {mainBackgroundType === `video` ? (
          <>
            <MainBackgroundVideoPc autoPlay muted loop playsInline>
              <source
                src="https://cdn.unicef.or.kr/video/main_background_pc.mp4"
                type="video/mp4"
              />
            </MainBackgroundVideoPc>
            <MainBackgroundVideoM autoPlay muted loop playsInline>
              <source
                src="https://cdn.unicef.or.kr/video/main_background_m.mp4"
                type="video/mp4"
              />
            </MainBackgroundVideoM> */}
        <MainBackgroundOverlay
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <MainSwiper
            onInit={onInit}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 8000, disableOnInteraction: false }}
          >
            {banners.map((banner, index) => (
              <SwiperSlide key={banner.date}>
                <ResponsiveImage
                  pcImg={banner.pcImage}
                  mobileImg={banner.mobileImage}
                >
                  <MainSwiperSlideContent>
                    <div className="title">
                      {banner.title?.split('\\n').map((t) => (
                        <div>
                          <span>{t}</span>
                        </div>
                      ))}
                    </div>
                    <div className="subTitle">
                      <span>{banner?.subTitle}</span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      {banner?.donateUrl && (
                        <Link
                          to={banner.donateUrl}
                          target={banner.linkTargetBlank ? '_blank' : '_self'}
                          style={{ marginRight: '10px' }}
                        >
                          <Button className="button">
                            <span>바로 후원하기</span>
                          </Button>
                        </Link>
                      )}
                      <Link
                        to={banner.link}
                        target={banner.linkTargetBlank ? '_blank' : '_self'}
                      >
                        <Button className="button">
                          <span>자세히 보기</span>
                        </Button>
                      </Link>
                    </div>
                  </MainSwiperSlideContent>
                </ResponsiveImage>
              </SwiperSlide>
            ))}
          </MainSwiper>
        </MainBackgroundOverlay>
        {/* </>
        ) : (
          <MainBackgroundImage src={mainBg} alt="메인 배경" />
        )} */}
      </MainSection>

      <SectionDirect>
        <Container>
          <HomeSupportForm />
        </Container>
      </SectionDirect>

      {/* 투명성 / 신뢰도 */}
      <Section marginTop="180px">
        <Container>
          <Header>
            <div>
              <Tit size="s1" className="ellipsis-underline">
                UN공식
              </Tit>
              <Tit size="s1">기관</Tit>
              <Tit size="s1" style={{ marginBottom: '20px', display: 'block' }}>
                유니세프
              </Tit>
              <p>
                유엔아동권리협약에 유일하게 명시된 아동권리 증진기구 유니세프,
              </p>
              <p>
                후원자님의 소중한 후원금으로 전 세계 어린이 및 한국 어린이를
                돕습니다.
              </p>
            </div>
          </Header>

          <FlexResponsiveWrapper style={{ marginBottom: '10px' }}>
            <div className="wrapper">
              <FlexWrapper>
                <div
                  className="img-wrapper-un"
                  style={{ backgroundImage: `url(${TransparencyUn})` }}
                />
                <div className="text-wrapper">
                  <Tit size="s6" color="sky" style={{ display: 'inline' }}>
                    UN 공식 기관
                  </Tit>
                  <Tit size="s6" style={{ display: 'inline' }}>
                    으로{' '}
                  </Tit>
                  <Tit size="s6" color="sky" style={{ display: 'inline' }}>
                    약 80년의 구호 활동 경험과 전문성
                  </Tit>
                </div>
              </FlexWrapper>
              <FlexWrapper>
                <div
                  className="img-wrapper-cnc"
                  style={{ backgroundImage: `url(${TransparencyCncLogo})` }}
                />
                <div className="text-wrapper">
                  <Tit size="s6" color="sky" style={{ display: 'inline' }}>
                    유엔아동권리협약에 직접 명시
                  </Tit>
                  <Tit size="s6" style={{ display: 'inline' }}>
                    된 유일한 아동권리증진 기관
                  </Tit>
                </div>
              </FlexWrapper>
              <FlexWrapper>
                <div
                  className="img-wrapper"
                  style={{ backgroundImage: `url(${TransparencyIcon})` }}
                />
                <div className="text-wrapper">
                  <Tit size="s6" style={{ display: 'inline' }}>
                    유니세프 본부 및 외부 회계 법인 감사로{' '}
                  </Tit>
                  <Tit size="s6" color="sky" style={{ display: 'inline' }}>
                    후원금 사용의 투명성 검증
                  </Tit>
                </div>
              </FlexWrapper>
              <FlexWrapper>
                <div
                  className="img-wrapper"
                  style={{ backgroundImage: `url(${TransparencyAward})` }}
                />
                <div className="text-wrapper">
                  <Tit size="s6" style={{ display: 'inline' }}>
                    2023 대한민국 국가사회산업공헌 대상 비영리 기관{' '}
                  </Tit>
                  <Tit size="s6" color="sky" style={{ display: 'inline' }}>
                    &apos;세계 어린이 구호&apos; 부문 3년 연속 수상
                  </Tit>
                </div>
              </FlexWrapper>
              <FlexWrapper>
                <div
                  className="img-wrapper"
                  style={{ backgroundImage: `url(${TransparencyIati})` }}
                />
                <div className="text-wrapper">
                  <Tit size="s6" style={{ display: 'inline' }}>
                    2022년 원조투명성지수 평가 에서{' '}
                  </Tit>
                  <Tit size="s6" color="sky" style={{ display: 'inline' }}>
                    2회 연속 최상위 등급 (Very Good) 획득
                  </Tit>
                </div>
              </FlexWrapper>
            </div>
          </FlexResponsiveWrapper>
          <FlexResponsiveBannerWrapper>
            <Link to="/about-us/notice/82240" className="banner">
              <div>
                <Tit size="s5-l" style={{ display: 'block' }} className="title">
                  유니세프 팩트체크
                </Tit>
                <Tit size="s2-l" className="subTitle">
                  유니세프, 믿을 수 있나요?
                </Tit>
              </div>
              <div>
                <img
                  className="image2"
                  src={TransparencyComment}
                  alt="comment"
                />
              </div>
            </Link>

            <Link to="/about-us/clear/fund/unicef-kr" className="banner">
              <div>
                <Tit size="s5-l" style={{ display: 'block' }} className="title">
                  후원금 사용 내역
                </Tit>
                <Tit size="s2-l" className="subTitle">
                  후원금은 어떻게 쓰이나요?
                </Tit>
              </div>
              <div>
                <img className="image2" src={TransparencyMoney} alt="money" />
              </div>
            </Link>
          </FlexResponsiveBannerWrapper>
        </Container>
      </Section>

      {/* 유니세프 구호 현장 */}
      {reliefPlaces.length > 0 && (
        <SectionRelief>
          <Container>
            <Header>
              <h2>
                <Tit size="s1">유니세프 구호 현장</Tit>
              </h2>
            </Header>
            <SwiperCustom
              className="reliefSwiper"
              onMouseEnter={reliefSwiperMouseOn}
              onMouseLeave={reliefSwiperMouseLeave}
            >
              <Swiper
                onInit={reliefInit}
                onSlideChange={reliefSwiperChange}
                navigation
                slidesPerView={1}
                autoplay={{ delay: 6000 }}
                loop={reliefPlaces.length > 1}
              >
                {reliefPlaces.map((relief) => (
                  <SwiperSlide key={relief.id}>
                    <div className="relief-tit">
                      <Tit size="s2">
                        지금{' '}
                        <span className="underline">
                          <span>{relief.keyword}</span>
                        </span>
                        {attachParticle(relief.keyword)}
                      </Tit>
                    </div>
                    <div className="relief-con">
                      {relief.visualType === 'youtube' ? (
                        <div className="relief-video">
                          <YouTube
                            className="youtube_wrap"
                            videoId={relief.visualUri.slice(32)}
                            opts={{
                              height: '100%',
                              width: '100%',
                              playerVars: {
                                autoplay: 0, //자동재생 O
                                rel: 0, //관련 동영상
                                controls: 0, // 컨트롤바 숨김
                              },
                            }}
                            onReady={(e) => {
                              reliefPlacesYt[e.target.g.id] = e.target;
                            }}
                          />
                        </div>
                      ) : (
                        <div className="relief-img">
                          <Image
                            pcSrc={relief.imgAttGrpNoPc}
                            mobileSrc={relief.imgAttGrpNoMobile}
                            alt={relief.keyword + relief.visualType}
                          />
                        </div>
                      )}
                      <div className="relief-news">
                        <ul>
                          <ReliefPlaceCard keyword={relief.keyword || ''} />
                        </ul>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperCustom>
          </Container>
        </SectionRelief>
      )}

      {/* 유니세프 소식 */}
      <SectionStory paddingTop="180px">
        <Container>
          <Header>
            <h2>
              <Tit size="s1">유니세프 소식</Tit>
            </h2>
            <Link to="/what-we-do/news" className="more">
              <span className="txt">자세히 보기</span>
            </Link>
          </Header>
        </Container>

        {news.length > 0 && <BoardBannerCard boards={news} />}
      </SectionStory>

      <SectionActivity className="by-sub-main-layout">
        <Container
          css={`
            padding-top: 100px;
          `}
        >
          <Header style={{ paddingBottom: '40px' }}>
            <Tit size="s1">유니세프아동친화사회 만들기</Tit>
          </Header>
          <p style={{ paddingBottom: '60px' }}>
            유니세프아동친화사회 만들기 사업은 아동이 생활하는 사회(지역사회,
            학교 등)와 아동에게 영향을 미치는 사회(기업 등)를 모두 아동에게
            친화적인 환경으로 만들기 위한 프로젝트입니다. 유니세프한국위원회는
            이러한 목적 달성을 위해 유니세프아동친화도시, 유니세프아동친화학교,
            유니세프아동친화기업 등을 추진하며 점차 그 영역을 확대해 나가고
            있습니다.
          </p>
          <KidsFriendlyImageWrapper>
            <Link
              className="image-container"
              to="/what-we-do/advocacy-for-children/public-private-partnership/child-friendly-cities"
            >
              <div
                className="image"
                style={{ backgroundImage: `url(${KidsFriendlCity})` }}
              />
              <div>유니세프아동친화도시</div>
            </Link>
            <Link
              className="image-container"
              to="/what-we-do/advocacy-for-children/public-private-partnership/child-friendly-schools"
            >
              <div
                className="image"
                style={{ backgroundImage: `url(${KidsFriendlySchool})` }}
              />
              <div>유니세프아동친화학교</div>
            </Link>
            <Link
              className="image-container"
              to="/what-we-do/advocacy-for-children/public-private-partnership/childrens-rights-and-business"
            >
              <div
                className="image-small"
                style={{ backgroundImage: `url(${KidsFriendlyCorp})` }}
              />
              <div>유니세프아동친화기업</div>
            </Link>
          </KidsFriendlyImageWrapper>
        </Container>
        {/* <TabPanel tabs={tab} /> */}
      </SectionActivity>

      <SectionEvent>
        <Container>
          <Header>
            <h2>
              <Tit size="s1">이벤트/캠페인</Tit>
            </h2>
          </Header>
        </Container>
        <div className="content-inner--event">
          <MainEventCard
            src={MainEventImage}
            title="빅히트 뮤직·방탄소년단의 행보에 동참해주세요!"
            titleHash=""
            subtext="Donate together with BigHit Music·BTS"
            link1="/campaign/endviolence/dn"
            link2="/what-we-do/for-every-child/endviolence/en/donate"
          />
        </div>
      </SectionEvent>

      {campaigns.length > 0 && (
        <BnSlide>
          <CampaignCard campaigns={campaigns} mainEvent overflowHidden />
        </BnSlide>
      )}

      <SectionInterview>
        <Container>
          <Header>
            <h2>
              <Tit size="s1">유니세프와 사람들</Tit>
            </h2>
            <Link to="/about-us/people/" className="more">
              <span className="txt">자세히 보기</span>
            </Link>
          </Header>
        </Container>
        {people.length > 0 && <RoundThumbnailCard rounds={people} />}
      </SectionInterview>

      <Container>
        <ImageLinkCard images={contributionImages} />
      </Container>

      <SectionPartner>
        <SwiperCustom className="SwiperCustom" overflowHidden>
          <Swiper
            navigation={false}
            slidesPerView={6}
            autoplay
            breakpoints={{
              769: {
                slidesPerView: 6,
              },
              320: {
                slidesPerView: 3,
              },
            }}
          >
            {partners.map((partner) => (
              <SwiperSlide key={partner.id}>
                <Button
                  className="link-item"
                  onClick={() => {
                    clickPartners();
                  }}
                >
                  <CardLayout>
                    <CardImage>
                      <div
                        className="img-box"
                        css={`
                          background-image: url(${partner.imgPath});
                        `}
                      >
                        <span className="a11y">{partner.title}</span>
                      </div>
                    </CardImage>
                  </CardLayout>
                </Button>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperCustom>
      </SectionPartner>

      <SectionForm>
        <HomeSubscribeForm />
      </SectionForm>
      <EmergencyPopup kind={1} />
    </MainLayout>
  );
};

export default Home;
